.margin-left-100 {
  margin-left: 100px;
}
.margin-right-100 {
  margin-right: 100px;
}
.margin-top-100 {
  margin-top: 100px;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-100 {
  margin: 100px;
}
.margin-left-95 {
  margin-left: 95px;
}
.margin-right-95 {
  margin-right: 95px;
}
.margin-top-95 {
  margin-top: 95px;
}
.margin-bottom-95 {
  margin-bottom: 95px;
}
.margin-95 {
  margin: 95px;
}
.margin-left-90 {
  margin-left: 90px;
}
.margin-right-90 {
  margin-right: 90px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-bottom-90 {
  margin-bottom: 90px;
}
.margin-90 {
  margin: 90px;
}
.margin-left-85 {
  margin-left: 85px;
}
.margin-right-85 {
  margin-right: 85px;
}
.margin-top-85 {
  margin-top: 85px;
}
.margin-bottom-85 {
  margin-bottom: 85px;
}
.margin-85 {
  margin: 85px;
}
.margin-left-80 {
  margin-left: 80px;
}
.margin-right-80 {
  margin-right: 80px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-80 {
  margin: 80px;
}
.margin-left-75 {
  margin-left: 75px;
}
.margin-right-75 {
  margin-right: 75px;
}
.margin-top-75 {
  margin-top: 75px;
}
.margin-bottom-75 {
  margin-bottom: 75px;
}
.margin-75 {
  margin: 75px;
}
.margin-left-70 {
  margin-left: 70px;
}
.margin-right-70 {
  margin-right: 70px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-70 {
  margin: 70px;
}
.margin-left-65 {
  margin-left: 65px;
}
.margin-right-65 {
  margin-right: 65px;
}
.margin-top-65 {
  margin-top: 65px;
}
.margin-bottom-65 {
  margin-bottom: 65px;
}
.margin-65 {
  margin: 65px;
}
.margin-left-60 {
  margin-left: 60px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-60 {
  margin: 60px;
}
.margin-left-55 {
  margin-left: 55px;
}
.margin-right-55 {
  margin-right: 55px;
}
.margin-top-55 {
  margin-top: 55px;
}
.margin-bottom-55 {
  margin-bottom: 55px;
}
.margin-55 {
  margin: 55px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-right-50 {
  margin-right: 50px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-50 {
  margin: 50px;
}
.margin-left-45 {
  margin-left: 45px;
}
.margin-right-45 {
  margin-right: 45px;
}
.margin-top-45 {
  margin-top: 45px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}
.margin-45 {
  margin: 45px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-40 {
  margin: 40px;
}
.margin-left-35 {
  margin-left: 35px;
}
.margin-right-35 {
  margin-right: 35px;
}
.margin-top-35 {
  margin-top: 35px;
}
.margin-bottom-35 {
  margin-bottom: 35px;
}
.margin-35 {
  margin: 35px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-30 {
  margin: 30px;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-right-25 {
  margin-right: 25px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-25 {
  margin: 25px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-20 {
  margin: 20px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-15 {
  margin: 15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-10 {
  margin: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-5 {
  margin: 5px;
}
.margin-left-0 {
  margin-left: 0px;
}
.margin-right-0 {
  margin-right: 0px;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-bottom-0 {
  margin-bottom: 0px;
}
.margin-0 {
  margin: 0px;
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.btn-rounded {
  border-radius: 30px !important;
  padding: 0  !important;
  font-weight: 600  !important;
  border: 2px solid #888;
  width: 30px;
  height: 30px;
  color: #fff  !important;
  font-size: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: none  !important;
}
.btn-rounded i.fa {
  margin: 0;
  padding: 0;
}
.btn-rounded span {
  display: none;
}
.btn-rounded:focus {
  border-color: #00aeef;
  border-radius: 20px;
  outline: none;
}
.btn-rounded:hover {
  border-color: #00aeef;
  background-color: #00aeef;
  color: white;
  border-radius: 20px;
}
.btn-rounded.active {
  background-color: #00aeef;
  color: white;
}
.background-login-register {
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  background: #333;
  background-size: auto auto;
  width: 100%;
  height: 100%;
  z-index: -200;
  top: 0;
  left: 0;
}
.header-container {
  position: absolute;
  width: 100%;
  top: 15px;
}
.header-container .logo-container {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-login {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.content-register {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#playground-container {
  height: 500px;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}
.main {
  margin: 50px 15px;
}
h1.title {
  font-size: 50px;
  font-family: 'Passion One', cursive;
  font-weight: 400;
}
hr {
  width: 10%;
  color: #fff;
}
.form-group {
  margin-bottom: 15px;
}
label {
  margin-bottom: 15px;
}
input,
input::-webkit-input-placeholder {
  font-size: 11px;
  padding-top: 3px;
}
.main-login {
  background-color: #fff;
  /* shadows and rounded borders */
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.form-control {
  height: auto !important;
  padding: 8px 12px !important;
}
.input-group {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.21) !important;
}
#button {
  border: 1px solid #ccc;
  margin-top: 28px;
  padding: 6px 12px;
  color: #666;
  text-shadow: 0 1px #fff;
  cursor: pointer;
  border-radius: 3px 3px;
  box-shadow: 0 1px #fff inset, 0 1px #ddd;
  background: #f5f5f5;
  background: -moz-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  background: linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#eeeeee', GradientType=0);
}
.main-center {
  margin: 0 auto;
  max-width: 400px;
  padding: 10px 40px;
  background: #047fca;
  color: #FFF;
  text-shadow: none;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);
}
span.input-group-addon i {
  color: #009edf;
  font-size: 17px;
}
.login-button {
  font-size: 14px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-button {
  font-size: 14px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-alone-button {
  font-size: 18px;
  text-align: center;
  margin: auto;
  width: 120px;
  position: relative;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}
.background-body-index {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  background-size: 100% 100%;
  height: 100%;
  z-index: -100;
}
.background-body-article {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  height: 100%;
  z-index: -100;
}
h3.title-header-content {
  font-family: 'Open Sans', sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-weight: 300;
}
.line-header {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  background: url("/images/assets/line-bottom-now-playing.png");
  width: 79px;
  height: 4px;
}
.header-mobile-container {
  width: 100%;
  top: 15px;
  padding-bottom: 10px;
  background: #000;
}
.header-mobile-container .logo-container {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.header-mobile-container .navbar .header-article {
  top: 0;
  right: 0;
  left: 0px;
  position: fixed;
  background: #047fca;
  text-align: left;
  z-index: 200;
}
.header-mobile-container .navbar .header-article .header-article-detail {
  color: #fff;
  position: relative;
  vertical-align: middle;
  bottom: 4px;
  font-size: 20px;
}
.header-mobile-container .navbar .header-article .header-article-facebook {
  display: block;
  color: white;
  position: absolute;
  vertical-align: middle;
  width: auto;
  top: 8px;
  right: 65px;
}
.header-mobile-container .navbar .header-article .header-article-facebook .img-facebook {
  width: 35px;
  border: 0;
  display: inline;
}
.header-mobile-container .navbar .header-article .header-article-twitter {
  display: block;
  color: white;
  position: absolute;
  vertical-align: middle;
  width: auto;
  top: 8px;
  right: 110px;
}
.header-mobile-container .navbar .header-article .header-article-twitter .img-twitter {
  width: 35px;
  border: 0;
  display: inline;
}
.header-mobile-container .navbar .header-article .header-article-share {
  display: block;
  color: white;
  position: absolute;
  vertical-align: middle;
  width: auto;
  top: 0;
  right: 0px;
}
.header-mobile-container .navbar .header-article .btn-share {
  background: none;
  border: none;
  border-radius: 0;
  padding: 8px 20px;
  outline: none;
  font-size: 18pt;
  font-weight: 100;
  display: block;
  text-align: center;
}
.header-mobile-container .navbar .header-article .btn-back-to-menu {
  background: none;
  border: none;
  border-radius: 0;
  padding: 8px 20px;
  display: inline-block;
  outline: none;
  color: white;
  font-size: 18pt;
  text-align: center;
}
.header-mobile-container .navbar .header-article .btn-share:hover {
  color: #fc9230;
}
.header-mobile-container .navbar .header-article .btn-back-to-menu:hover {
  color: #fc9230;
}
.header-mobile-container .btn-header-container {
  background: rgba(0, 174, 239, 0.5);
  border: none;
  border-radius: 0;
  margin: 0;
  margin-bottom: 3px;
  display: inline-block;
  outline: none;
  color: white;
}
.header-mobile-container .btn-header-container:hover {
  color: white;
  border: none;
  outline: none;
  border-radius: 0px;
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.hidden-header {
  height: 50px;
  background: red;
  width: 100%;
  position: fixed;
  z-index: 1;
}
.mobile-navbar-container {
  background: rgba(0, 174, 239, 0.5);
  height: 54px;
}
.navbar-image {
  float: left;
  width: 160px;
  height: 54px;
  padding: 10px;
}
.navbar-button {
  float: right;
  width: 58px;
  height: 54px;
  padding: 10px;
}
.navbar-button button {
  background-color: #333;
  color: #fff;
  border: none;
}
.copyright-container {
  display: none;
}
.tab-option {
  position: relative;
  background: white;
  top: 0;
  z-index: 102;
  width: 100%;
}
.tab-option .tab-button {
  display: block;
  width: 50%;
  float: left;
  border-radius: 0;
  color: black;
  border: none;
  outline: none;
  background: white;
  font-size: 20px;
}
.tab-option .tab-button:hover {
  color: white;
  border: none;
  outline: none;
  border-radius: 0px;
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.schedule-container {
  position: absolute;
  top: 90px;
  width: 100%;
}
.schedule {
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 500px;
  padding: 0;
}
.schedule .bx-wrapper {
  background: none;
  box-shadow: none;
  border: none;
}
.schedule .slide {
  position: relative;
}
.schedule .slide .bx-slider {
  top: 0;
  left: 0;
  padding-left: 0;
  list-style: none;
}
.schedule .slide .slider-controls {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 100;
}
.schedule .slide .slider-controls .left-control {
  width: 30px;
  height: 30px;
  float: left;
}
.schedule .slide .slider-controls .right-control {
  width: 30px;
  height: 30px;
  float: right;
}
.schedule .header {
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-family: sans-serif;
  font-weight: bolder;
  margin-bottom: 5px;
}
.schedule .content {
  display: block;
}
.schedule .content ul.content-schedule {
  height: auto;
  list-style: none;
  background: none;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}
.schedule .content ul.content-schedule li {
  overflow: hidden;
  color: #d0d0d0;
  text-transform: uppercase;
}
.schedule .content ul.content-schedule li .left-side {
  display: inline-block;
  width: 15%;
  height: 40px;
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  line-height: 40px;
}
.schedule .content ul.content-schedule li .right-side {
  width: 85%;
  float: right;
  display: block;
}
.schedule .content ul.content-schedule li .right-side .title-row {
  overflow: hidden;
  position: relative;
  font-family: sans-serif;
  font-weight: bold;
}
.schedule .content ul.content-schedule li .right-side .time-row {
  overflow: hidden;
}
.schedule .content ul.content-schedule li .right-side .time-row .time {
  float: left;
  margin: 0 2px;
}
.schedule .content ul.content-schedule li .right-side .time-row .time:first-child {
  margin-left: 0;
}
.schedule .content ul.content-schedule li .right-side .time-row .time:after {
  content: " - ";
}
.schedule .content ul.content-schedule li .right-side .time-row .time:last-child:after {
  content: "";
}
.schedule .header-date {
  overflow: hidden;
}
.schedule .header-date .header-date-text {
  display: inline-block;
  line-height: 30px;
  margin: 5px;
  font-size: 15px;
  color: white;
}
.schedule .header-date .button-list {
  display: inline-block;
  margin: 5px 0;
  float: right;
}
.content-about {
  color: #000000;
  background: #efefef;
  padding: 20px 0;
}
.content-movie-menu {
  z-index: 102;
  width: 100%;
  position: relative;
}
.news-container {
  background: #333;
  padding: 20px 0 0 0;
  overflow: hidden;
}
.content-mobile-now-playing {
  width: 100%;
  background: #222;
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
}
.content-mobile-now-playing h3 {
  color: white;
}
.content-mobile-now-playing .container .row {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-now-playing .container .row .items {
  height: 200px;
  width: 25%;
  float: left;
  position: relative;
}
.content-mobile-now-playing .container .row .brick-image {
  position: absolute;
  background-size: 100% 100%;
  height: 200px;
  width: 100%;
}
.content-mobile-now-playing .container .row .brick-info {
  position: absolute;
  height: 200px;
  width: 100%;
  top: 0;
  -moz-transition-duration: .5s;
  transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.content-mobile-now-playing .container .row .brick-info .brick-info-detail {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
}
.content-mobile-now-playing .container .row .brick-info .brick-info-detail h5 {
  font-family: 'Open Sans', sans-serif;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: middle;
  font-size: 10pt;
}
.content-mobile-now-playing .container .row .brick-info-order {
  background: url(/images/assets/nowplaying.png);
  background-size: 100% 100%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
}
.content-mobile-coming-soon {
  width: 100%;
  background: white;
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #047fca, black);
  /* For Firefox 3.6 to 15 */
  background: -moz-linear-gradient(left, #047fca, black);
  background: linear-gradient(to right, #047fca, black);
  /* Standard syntax */
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}
.content-mobile-coming-soon h3 {
  color: white;
}
.content-mobile-coming-soon .container .row {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-coming-soon .container .row .items {
  height: 200px;
  width: 25%;
  float: left;
  position: relative;
}
.content-mobile-coming-soon .container .row .brick-image {
  position: absolute;
  background-size: 100% 100%;
  height: 200px;
  width: 100%;
}
.content-mobile-coming-soon .container .row .brick-info {
  position: absolute;
  height: 200px;
  width: 100%;
  top: 0;
  -moz-transition-duration: .5s;
  transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.content-mobile-coming-soon .container .row .brick-info .brick-info-detail {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
}
.content-mobile-coming-soon .container .row .brick-info .brick-info-detail h5 {
  font-family: 'Open Sans', sans-serif;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: middle;
  font-size: 10pt;
}
.content-mobile-coming-soon .container .row .brick-info-order {
  background: url(/images/assets/upcoming.png);
  background-size: 100% 100%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
}
.content-mobile-box-office {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  z-index: 101;
  top: 2050px;
  left: 0;
  right: 0;
  min-height: 900px;
  margin-bottom: 150px;
}
.content-mobile-box-office h3 {
  color: white;
}
.content-mobile-box-office .row {
  width: 100%;
}
.content-mobile-box-office .container .movimax-sarinah {
  display: block;
  top: 0;
  position: absolute;
  width: 100%;
}
.content-mobile-box-office .container .movimax-dinoyo {
  display: block;
  top: 350px;
  position: absolute;
  width: 100%;
}
.content-mobile-box-office .container .cinema .cinema-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  width: 100%;
}
.content-mobile-box-office .container .cinema .cinema-container .cinema-content {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  margin-top: 10px;
  color: white;
}
.content-mobile-box-office .container .cinema .cinema-container table.cinema-table {
  width: 100%;
}
.content-mobile-box-office .container .cinema .cinema-container table.cinema-table > tbody > tr > td {
  font-size: 12px;
  color: white;
}
.content-mobile-box-office .container .col-xs-12 .cinema img {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  width: 250px;
}
.content-mobile-box-office .container .col-xs-12 .cinema .maps {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-features {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: #dedede;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 50px;
}
.content-mobile-features h3 {
  color: black;
}
.content-mobile-features .container .row {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.content-mobile-features .container .row .box-shadow {
  text-align: center;
  margin-top: 10px;
}
.box-shadow .logo-features-dlp {
  background-size: 100% 100%;
  position: relative;
  width: 150px;
  height: 70px;
  bottom: -5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 768px) {
  .box-shadow .logo-features-dolby {
    background-size: 100% 100%;
    position: relative;
    width: 158px;
    height: 32px;
    bottom: -8px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 769px) {
  .box-shadow .logo-features-dolby {
    background-size: 100% 100%;
    position: relative;
    width: 180px;
    height: 36px;
    bottom: -30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
  .content-mobile-features .container .row .box-shadow .logo-features-barco {
    background-size: 100% 100%;
    position: relative;
    width: 150px;
    height: 45px;
    bottom: -20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 769px) {
  .content-mobile-features .container .row .box-shadow .logo-features-barco {
    background-size: 100% 100%;
    position: relative;
    width: 180px;
    height: 55px;
    bottom: -25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.content-mobile-features .container .row .box-shadow h4 {
  font-size: 1.2em;
  font-weight: 800;
  margin: 10px;
}
.content-mobile-features .container .row .box-shadow > p {
  font-size: 9pt;
  margin: 3px;
}
.logo-features-dlp {
  margin: 0 auto;
  width: 200px;
  display: block;
}
.logo-features {
  margin: 20px auto 0 auto;
  width: 200px;
  display: block;
}
.content-mobile-info {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 550px;
  margin-bottom: 35px;
}
.content-mobile-info h3 {
  color: white;
}
.content-mobile-info .row {
  width: 100%;
}
.content-mobile-info .container .row .col-xs-12 .content-mobile-info-table {
  width: 100%;
  position: absolute;
  color: white;
  text-align: center;
  font-size: 10pt;
}
.content-mobile-footer-index {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: #e6e6e6;
  position: relative;
  height: 42px;
}
.content-mobile-footer-index h4 {
  text-align: center;
  position: absolute;
  width: 100%;
}
.content-mobile-footer-article {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
}
.content-mobile-footer-article h4 {
  text-align: center;
  position: absolute;
  width: 100%;
}
.content-mobile-article {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: none;
  z-index: 101;
  left: 0;
  right: 0;
  color: #000;
}
.article-title {
  font-family: "arial black", arial, fantasy;
  font-size: 20px;
}
.content-mobile-article .container .row {
  width: 100%;
}
.content-mobile-article .container .row .col-xs-5 {
  height: 240px;
}
.content-mobile-article .container .row .col-xs-7 {
  height: 240px;
  padding-left: 30px;
}
.content-mobile-article .container .row .col-xs-5 .article-img-background {
  position: absolute;
  background-size: 100% 100%;
  width: 167px;
  height: 240px;
}
.content-mobile-article .container .row .col-xs-7 .article-header-background {
  background: none;
  width: 55%;
  height: 220px;
}
.content-mobile-article .container p {
  margin-top: 10px;
}
.content-mobile-article .container .row .article-header-background h5 {
  color: white;
  padding: 0;
  margin: 0;
  font-weight: bolder;
  font-size: 18pt;
}
.content-mobile-article .container .row .article-header-background .button-article-header {
  margin-top: 30px;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-trailer {
  background: #ca0404;
  border: none;
  border-radius: 0;
  margin: 15px 0;
  display: inline-block;
  outline: none;
  color: white;
  font-size: 10pt;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-ticket {
  background: #047fca;
  border: none;
  border-radius: 0;
  margin: 0;
  margin-bottom: 3px;
  display: inline-block;
  outline: none;
  color: white;
  font-size: 10pt;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-trailer:hover {
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-ticket:hover {
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.content-mobile-article .container ul.cast-catalog {
  list-style: none;
  padding: 0;
}
.content-mobile-article .container ul.cast-catalog li {
  margin-bottom: 15px;
}
.content-mobile-article .container ul.cast-catalog li .artist-link {
  position: relative;
}
.content-mobile-article .container ul.cast-catalog li .artist-link img {
  width: 120px;
  margin-right: 10px;
}
.content-mobile-article .container ul.cast-catalog li .artist-link strong.artist-name {
  position: absolute;
}
.content-mobile-article .container ul.cast-catalog li .artist-link p.artist-bio {
  width: 320px;
  display: inline-block;
  position: absolute;
  top: 25px;
  font-size: 12px;
}
.content-mobile-article table.table-article {
  width: 100%;
  background: none;
  margin-top: 10px;
}
.content-mobile-article table.table-article > tbody > tr > td,
th {
  border-bottom: 1px solid #aaa;
  padding: 10px 15px;
  font-size: 12px;
  position: relative;
  background: none;
}
.content-mobile-article table.table-article > tbody > tr:first-child > td,
th {
  border-top: 1px solid #aaa;
}
.content-mobile-article table.table-article > tbody > tr:first-child > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  top: -8px;
  right: -4px;
}
.content-mobile-article table.table-article > tbody > tr:first-child > td:last-child:before {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -9px;
  right: -3px;
}
.content-mobile-article table.table-article > tbody > tr > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -9px;
  right: -3px;
}
.content-mobile-article-playlist {
  width: 100%;
  background: none;
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  color: white;
}
.content-mobile-article-playlist h3 {
  color: white;
}
.content-mobile-article-playlist .row {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-article-playlist .container .row .items {
  height: 250px;
  width: 50%;
  float: left;
  margin: 0;
  position: relative;
  padding: 5px;
}
.content-mobile-article-playlist .container .row .brick-image {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}
.content-mobile-article-playlist .container .row .brick-info {
  position: absolute;
  height: 200px;
  -moz-transition-duration: .5s;
  transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  bottom: 0;
  left: 5px;
  right: 5px;
}
.content-mobile-article-playlist .container .row .brick-info .brick-info-detail {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
}
.content-mobile-article-playlist .container .row .brick-info .brick-info-detail h5 {
  font-family: 'Open Sans', sans-serif;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: middle;
  font-size: 10px;
}
.content-mobile-article-playlist .container .row .brick-info-order {
  background: url(/images/assets/nowplaying.png);
  background-size: 100% 100%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 5px;
  left: 5px;
}
.select-schedule-container {
  color: white;
  padding: 0 8px;
}
.select-schedule-container .select-theme {
  background: #282828;
  border: 1px solid #555;
  font-size: 15px;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-appearance: none;
}
.select-schedule-container .select-theme .option-theme {
  padding: 5px 10px;
  line-height: 22px;
  text-align: center;
}
.select-schedule-container .select-theme .option-theme:before {
  content: "";
  background-image: url(/images/assets/logo-small.png);
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  margin-right: 10px;
  word-wrap: break-word;
  margin-top: -4px;
}
.content-schedule-container {
  background: #333;
  margin: 15px 17px;
}
.content-schedule-container .img-container {
  height: 180px;
  width: 130px;
  display: none;
}
.content-schedule-container .img-container img {
  width: 100%;
  height: 100%;
}
.content-schedule-container .content-container {
  height: 100%;
  color: #fff;
  margin-left: 12px;
  padding: 15px;
}
.content-schedule-container .content-container .title {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #00aeef;
  padding-bottom: 5px;
}
.content-schedule-container .content-container .info-container {
  margin-top: 10px;
  position: relative;
}
.content-schedule-container .content-container .info-container .studio-container {
  float: right;
  display: block;
  width: 120px;
  height: 85px;
  position: relative;
  color: #fff;
}
.content-schedule-container .content-container .info-container .studio-container .label-studio {
  position: absolute;
  top: 0;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.content-schedule-container .content-container .info-container .studio-container .number {
  position: absolute;
  bottom: 12px;
  font-size: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: arial;
  font-weight: 700;
  text-decoration: none;
}
.content-schedule-container .content-container .info-container .studio-container .type {
  position: absolute;
  bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.content-schedule-container .content-container .info-container .studio-container:hover {
  background: #00aeef;
  color: #fff !important;
}
.content-schedule-container .content-container .info-container .info {
  display: inline;
  padding-right: 10px;
}
.content-schedule-container .content-container .info-container .font-size-15 {
  font-size: 14px;
}
.content-schedule-container .content-container .info-container .btn-group {
  width: 100%;
}
.content-schedule-container .content-container .info-container .btn-group .btn-schedule {
  border-radius: 0;
  background: #282828;
  border: 1px solid #333;
  font-family: Arial, Helvetica, sans-serif;
}
.description .content {
  font-size: 14px;
  color: white;
  padding: 15px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #333;
  background: #003f56;
}
.description .content.danger {
  background: #560000;
}
.circle {
  width: 60px;
  height: 60px;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
  top: 10px;
}
.circle .content {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 60px;
  text-align: center;
  width: 60px;
  height: 60px;
  font-size: 17pt;
  margin-top: 0 !important;
}
.circle .circle-label {
  position: absolute;
  bottom: -15px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 8px;
  display: block;
  width: 100%;
}
.circle.orange-circle {
  background-image: url('/images/assets/orange.png');
}
.circle.red-circle {
  background-image: url('/images/assets/red.png');
}
.circle.blue-circle {
  background-image: url('/images/assets/blue.png');
}
.circle.green-circle {
  background-image: url('/images/assets/green.png');
}
.btn-rounded {
  border: 2px solid #888;
  border-radius: 15px;
  height: 30px;
  margin-right: 2px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: none;
}
.btn-rounded i.fa {
  margin: 0;
  padding: 0;
  width: 15px;
}
.btn-rounded:focus {
  border-color: #00aeef;
  border-radius: 15px;
  outline: none;
}
.btn-rounded:hover {
  border-color: #00aeef;
  background-color: #00aeef;
  color: white;
}
.bx-wrapper {
  position: relative;
  margin-bottom: 30px;
  padding: 0;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  box-shadow: none;
  background: none;
  border: 5px solid #333;
}
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: #00aeef;
}
.schedule {
  padding: 10px;
}
body {
  width: 100%;
  height: 100%;
  background: #333333;
  color: #ddd;
}
.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
  overflow: hidden;
}
.slideout-menu-left {
  left: 0;
}
.slideout-menu-right {
  right: 0;
}
.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF;
  /* A background-color is required */
  min-height: 100vh;
}
.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}
.slideout-open .slideout-menu {
  display: block;
}
.header-slideout-container {
  position: relative;
  height: 50px;
}
.header-slideout-text {
  height: 50px;
  width: 100%;
}
.header-slideout-text span {
  font-size: 20px;
  float: left;
  padding: 12px 10px 10px 15px;
  height: 50px;
}
.header-slideout-text button {
  color: #fff;
  background-color: #444;
  border: none;
  float: right;
  margin: 10px;
}
.nav-container {
  background-color: #444;
  height: 40px;
  width: 100%;
  display: table;
  border-bottom: 0.4px solid #222;
}
.nav-logo {
  display: table-cell;
  width: 20%;
  text-align: center;
  vertical-align: middle;
}
.nav-logo i {
  margin-left: 3px;
  font-size: 20px;
  color: #ffffff;
}
.nav-text {
  display: table-cell;
  width: 80%;
  vertical-align: middle;
  font-size: 11px;
}
.nav-text a {
  color: white;
  text-align: left;
  padding: 10px 0px 10px 0;
}
.nav-text a:link {
  text-decoration: none;
}
.copyright-container {
  position: absolute;
  bottom: 0;
  margin-top: 15px;
  padding: 10px;
  font-size: 9px;
}
.mobile-error {
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #0c0e14 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #0c0e14 100%);
  margin: 0;
}
.mobile-error .title-error {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
}
.mobile-error .image-error {
  width: 45%;
  display: block;
  margin: 20px auto;
}
.mobile-error .page-not-found {
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
}
.mobile-error .btn-primary {
  margin-top: 5px;
}
.mobile-error .footer-error {
  margin-top: 30px;
  padding-bottom: 40px;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #0c0e14 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #0c0e14 100%);
}
.mobile-error .footer-error .image-info {
  width: 40%;
  display: block;
  margin: 20px auto;
}
.mobile-error .footer-error .info {
  font-size: 15px;
  margin: 0;
  text-align: center;
  color: #fff;
}
.bg-partnership {
  background: url("/images/assets/bg-partnership.png");
  background-size: 100% 100%;
  padding-bottom: 50px;
}
.bx-wrapper {
  background: transparent;
}
.partner {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
}
.partner .partner-title {
  font-weight: bold;
  font-size: 30px;
}
.partner .header-image {
  opacity: 0.7;
  width: 100%;
}
.partner .partner-join {
  position: relative;
  bottom: 157px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
}
.partner .grow-business {
  display: inline;
  position: absolute;
  left: 0;
  right: 0;
  top: 208px;
  width: 100%;
  left: 15px;
  padding: 0;
}
.partner .grow-business .panel-partnership {
  padding: 15px;
  color: #000;
  background: #fff;
  height: 170px;
}
.partner .grow-business .panel-partnership .bold {
  font-size: 20px;
}
.partner .grow-business .panel-partnership .panel {
  font-size: 12px;
}
.partner .grow-business .col-md-3 {
  padding: 0;
}
.partner .grow-business .col-md-3 .contact {
  background: #3e24b1;
  width: 100%;
  height: 225px;
  color: white;
  padding: 10px 10px 55px 20px;
}
.partner .grow-business .col-md-3 a.link {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
}
.partner .grow-business .col-md-3 a.link .contact:hover {
  background: #2507aa;
  cursor: pointer;
  color: white;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.partner .grow-business .col-md-3 a.link .contact:hover h3.hover {
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.partner .body-partner {
  color: #000;
  background: #fff;
  padding: 20px;
  margin-top: 240px;
}
.partner .body-partner .our-partnership {
  margin-top: 50px;
}
.partner .body-partner .our-partnership .bold {
  font-size: 22px;
}
.partner .body-partner .our-partnership .offer {
  font-size: 14px;
}
.partner .body-partner .blog {
  font-size: 14px;
  padding: 10px;
}
.partner .body-partner .img-blog {
  width: 100%;
  margin-bottom: 20px;
}
.partner .body-partner .bold-blog {
  font-weight: bold;
  font-size: 21px;
}
.partner .gallery {
  text-align: center;
}
.partner .gallery .gallery-title {
  font-weight: bold;
}
.partner .gallery .img-gallery {
  position: relative;
  min-height: 279px;
  max-height: 380;
}
.partner .gallery .line-bottom {
  width: 60px;
  margin-bottom: 20px;
}
.partner .gallery-place {
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
}
.partner .gallery-place img {
  width: 100%;
}
.partner .gallery-place img.logo {
  width: 50%;
  padding: 15px;
  margin: 0 auto;
  display: block;
}
.partner .footer {
  background: #222;
  height: 100%;
  width: 80%;
  text-align: center;
  padding: 40px 0;
}
.partner .footer .info-contact {
  letter-spacing: 5px;
  font-size: 17px;
}
.partner .footer a.contact {
  color: #fff;
  letter-spacing: 3px;
  font-size: 12px;
}
.mobile-confirm {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
  background: #eeeeee;
  /* Old browsers */
  background: -moz-linear-gradient(top, #eeeeee 0%, #eeeeee 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#eeeeee', GradientType=0);
  /* IE6-9 */
  padding: 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.mobile-confirm h3 {
  font-weight: bold;
}
.mobile-confirm .form-control {
  font-size: 50px;
  width: 80%;
  margin: 10px;
}
.mobile-confirm .btn-success {
  margin: 10px;
}
.mobile-confirm a.btn.btn-default {
  margin: 10px;
}
.mobile-confirm .verification {
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}
.news {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 195px;
  position: relative;
}
.news .swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.news .swiper-slide {
  padding: 0 40px;
  display: block;
}
.news .swiper-slide .swiper-img {
  width: 30%;
  display: inline-block;
}
.news .swiper-slide .swiper-content {
  width: 70%;
  display: inline-block;
  padding: 0 15px;
}
.news .swiper-slide .swiper-content .title {
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
.news .swiper-slide .swiper-content .info {
  font-size: 11px;
  color: #ddd;
}
.news .right {
  position: absolute;
  top: 45%;
  right: 5px;
  z-index: 10;
  border: 2px solid #888;
  border-radius: 15px;
  height: 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: 0;
  width: 30px;
  padding: 6px;
}
.news .left {
  position: absolute;
  top: 45%;
  left: 5px;
  z-index: 10;
  border: 2px solid #888;
  border-radius: 15px;
  height: 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: 0;
  width: 30px;
  padding: 6px;
}
.container {
  font-family: 'Roboto', sans-serif;
  color: black;
}
.container .title {
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  text-shadow: 1px 1px #333;
  z-index: 1;
}
.container .title .subtitle {
  font-size: 12px;
  font-weight: normal;
}
.container .share-button {
  width: 35px;
  margin: 0 5px;
}
.container img {
  position: relative;
  width: 100%;
}
.container .content {
  margin-top: 20px;
}
.container .content .content-title {
  font-weight: bold;
}
.container .content .text {
  font-size: 15px;
  line-height: 19px;
}
.container .more {
  text-align: center;
  font-weight: bold;
}
.container .line-header {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  background: url(/images/assets/line-bottom-now-playing.png);
  width: 79px;
  height: 4px;
}
.container .other-news {
  width: 45%;
  margin: 10px 6px;
  float: left;
}
.container .other-news .other-image {
  width: 100%;
  margin-bottom: 10px;
}
.container .other-news .other-title {
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.content-news-list-mobile {
  color: #000;
}
.content-news-list-mobile ul.headline-slider-mobile {
  list-style: none;
  padding: 0;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.content-news-list-mobile ul.headline-slider-mobile .image-header {
  position: relative;
  margin: 0;
  width: 100%;
  height: 360px;
  background-size: 100%;
  overflow: hidden;
}
.content-news-list-mobile ul.headline-slider-mobile .image-header img {
  width: 100%;
  height: 300px;
}
.content-news-list-mobile ul.headline-slider-mobile .image-header .title-header {
  position: absolute;
  bottom: 60px;
  font-size: 20px;
  font-weight: 700;
  background: rgba(9, 9, 9, 0.82);
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}
.content-news-list-mobile ul.headline-slider-mobile .image-header .title-header .subtitle-header {
  font-size: 10px;
  text-transform: none;
  font-weight: normal;
}
.content-news-list-mobile ul.headline-slider-mobile .image-header .content-header {
  margin-top: 5px;
  font-size: 14px;
  width: 100%;
  height: 14%;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
}
.content-news-list-mobile .content {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 15px;
  color: #000;
}
.content-news-list-mobile .content img {
  width: 25%;
}
.content-news-list-mobile .content .title-content {
  position: absolute;
  top: 1%;
  left: 28%;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  width: 70%;
  height: 40px;
  overflow: hidden;
}
.content-news-list-mobile .content .article {
  position: absolute;
  top: 25%;
  left: 28%;
  font-size: 13px;
}
.content-news-list-mobile .content .read-more {
  position: absolute;
  bottom: 5px;
  left: 28%;
  background: #337ab7;
  color: #fff;
  border-radius: 2px;
  font-weight: bold;
  border: 1px solid #337ab7;
  padding: 3px;
  text-decoration: none;
}
.content-news-list-mobile .highlight-topic {
  font-weight: 700;
  background: #0e8fe6;
  color: #fff;
  font-size: 14px;
  padding: 5px;
  margin-top: 10px;
  width: 160px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.content-news-list-mobile .line {
  width: 100%;
  border-bottom: 3px solid #0e8fe6;
}
.content-news-list-mobile .copyright-container-mobile {
  margin-top: 15px;
  padding: 10px;
  font-size: 9px;
  background: #000;
  color: #fff;
  margin-bottom: 10px;
  position: relative;
  height: 75px;
}
.content-news-list-mobile .copyright-container-mobile .email-support {
  position: absolute;
  top: 10px;
  left: 2%;
}
.content-news-list-mobile .copyright-container-mobile .contact {
  position: absolute;
  top: 10px;
  left: 80%;
}
.content-news-list-mobile .copyright-container-mobile .footer-movimax {
  position: absolute;
  top: 30px;
  left: 45%;
  text-align: center;
}
.header-studio {
  margin-top: 10px;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.header-studio .type-studio {
  font-size: 35px;
  font-weight: bold;
  margin: 0;
}
.header-studio .place-studio {
  font-size: 27px;
  font-weight: bold;
}
.studio-number {
  margin-top: 10px;
  border: 1px solid #000;
  text-align: center;
  padding: 0px;
  font-family: roboto, sans-serif;
}
.studio-number .number {
  font-weight: bold;
  font-size: 65px;
}
.studio-number .studio {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}
.line {
  margin-top: 10px;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
}
.content-info .title-info {
  font-size: 20px;
  font-weight: bold;
  text-transform: initial;
  margin-left: 10px;
}
.content-info .image-info {
  padding: 0 10px;
  margin-bottom: 5px;
  height: 300px;
}
.content-info .info {
  padding: 0px 10px;
  font-size: 15px;
  margin-bottom: 20px;
}
.title-gallery {
  font-size: 23px;
  font-weight: bolder;
}
.line-gallery {
  display: block;
  margin: 10px auto;
  height: 5px;
}
.image-round {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.image-round .pict-gallery {
  border-radius: 5px;
  max-width: 130px;
  max-height: 109px;
  min-height: 109px;
  margin: 10px;
}
.in-studio {
  list-style: none;
  padding: 0;
}
.in-studio li {
  margin: 10px 0px;
}
.in-studio li img {
  width: 100%;
  height: 310px;
}
.help-mobile {
  background: #f5f5f5;
  padding-bottom: 50px;
}
.help-mobile .input-group {
  margin-top: 10px;
  border-radius: 5px;
}
.help-mobile .input-group button {
  padding: 8px 12px;
  background: transparent;
}
.help-mobile .help-container [aria-expanded="false"] .plus-icon {
  display: block;
}
.help-mobile .help-container [aria-expanded="false"] .minus-icon {
  display: none;
}
.help-mobile .help-container [aria-expanded="true"] .plus-icon {
  display: none;
}
.help-mobile .help-container [aria-expanded="true"] .minus-icon {
  display: block;
}
.help-mobile .help-container .col-md-12 {
  margin: 1px;
}
.help-mobile .help-container .btn-rounded {
  display: inline-block;
}
.help-mobile .help-container .question-container {
  cursor: pointer;
  border-radius: 3px;
  margin: 1px 0;
  padding: 5px;
}
.help-mobile .help-container .question-container i {
  position: absolute;
  top: 13px;
  color: #00aeef;
}
.help-mobile .help-container .question-container .question-container[aria-expanded] {
  height: 240px;
}
.help-mobile .help-container .question-container .question {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  min-height: 20px;
  font-weight: bold;
  margin-left: 20px;
  border-bottom: 1px solid #ccc;
}
.help-mobile .help-container .well {
  background: #efefef;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 4px;
  margin: 0;
  font-size: 12px;
}
.align-center {
  text-align: center;
}
.bx-pager-item {
  display: none;
}
